<template>
  <div class="flex_column align_center mouse" :style="backgroundStyle">
    <div class="flex_column align_center" style="height: 90vh; width: 80vw;">
      <div class="flex_row">
        <!-- 主题色选择 -->
        <div v-show="themeShow" class="flex_row theme_position">
          <el-button 
          @click="handleThemeClick('light')"
          class="theme_button" 
          style="background: #fff;"></el-button>
          <el-button 
          class="theme_button" 
          @click="handleThemeClick('dark')"
          style="background: #000;"></el-button>
        </div>

        <div class="close_button">
          <el-button @click="handleCloseClick">
            <i class="el-icon-close"></i>
          </el-button>
        </div>
      </div>

      <div class="flex_row content_between align_center" style="width: 100%;">
        <!-- logo图片 -->
        <img class="logo_img" :src="logo">
        <!-- 导航栏 -->
        <el-radio-group 
        v-model="radio" 
        class="flex_row align_center" 
        style="justify-content: space-evenly;">
          <el-radio 
          label="1" 
          class="iconfont el-icon-main tab_radio" :class="radio == 1? 'tab_radio_checked' : 'tab_radio_unchecked'"
          :disabled="macroRecording"></el-radio>
          <el-radio 
          label="2" 
          class="iconfont el-icon-sensor tab_radio" :class="radio == 2? 'tab_radio_checked' : 'tab_radio_unchecked'"
          :disabled="macroRecording"></el-radio>
          <el-radio 
          label="3" 
          class="iconfont el-icon-macro tab_radio" :class="radio == 3? 'tab_radio_checked' : 'tab_radio_unchecked'"
          :disabled="macroRecording"></el-radio>
          <el-radio 
          label="4" 
          class="iconfont el-icon-light tab_radio" :class="radio == 4? 'tab_radio_checked' : 'tab_radio_unchecked'" 
          v-show="lightShow"
          :disabled="macroRecording"></el-radio>
          <el-radio 
          label="5" 
          class="iconfont el-icon-setting tab_radio" :class="radio == 5? 'tab_radio_checked' : 'tab_radio_unchecked'"
          :disabled="macroRecording"></el-radio>
        </el-radio-group>
        <!-- 电池显示 -->
        <battery v-show="batteryShow" style="width:10vw"></battery>
        <div v-show="batteryShow == false" style="width:10vw"></div>
      </div>
      <div class="flex_column margin_top" style="height: 60vh; width: 60vw;">
        <mouse-key v-show="radio == 1"></mouse-key>
        <mouse-sensor v-show="radio == 2"></mouse-sensor>
        <mouse-macro v-show="radio == 3"></mouse-macro>
        <mouse-light v-show="radio == 4"></mouse-light>
        <mouse-setting v-show="radio == 5"></mouse-setting>
      </div>
    </div>
    <tips ref="tipsDialog" :text="tipsText" :showButton="showButton"></tips>
  </div>
</template>

<script>
  import Battery from '../components/Battery';
  import MouseKey from './MouseKey.vue';
  import MouseSensor from './MouseSensor.vue';
  import MouseMacro from './MouseMacro.vue';
  import MouseLight from './MouseLight.vue';
  import MouseSetting from './MouseSetting.vue' 
  import HIDHandle from '@/assets/js/HIDHandle';
  import Tips from '../components/Dialog/Tips.vue'

  export default {
    components:{
      Battery,
      MouseKey,
      MouseSensor,
      MouseMacro,
      MouseLight,
      MouseSetting,
      Tips
    },
    data() {
      return {
        radio: '1',
        lastRadio:"1",
        lightShow:true,
        info:HIDHandle.deviceInfo,
        logo:"/img/logo_light.png",
        language:{},
        tipsVisible:false,
        tipsText:'',
        showButton:false,
        macroRecording:false,
        saveMacroFirst:false,
        theme:"",
        bgImage:'./img/bg_dark.png',
        batteryShow:false,
        themeShow:false,
        upgrade:null,
      };
    },
    methods: {
      handleThemeClick(theme) {
        console.log("theme",theme,this.theme);
        if(this.theme != theme) {
          this.theme = theme;         
          if(this.theme == "light")
            this.logo = window.location.href + "img/logo_dark.png";
          else
            this.logo = window.location.href + "img/logo_light.png";
          this.bgImage = window.location.href + `img/bg_${theme}.png`;
          this.$bus.$emit("themeChange",theme);
        }
      },
      handleCloseClick() {
        if(this.saveMacroFirst == false) {
          HIDHandle.Device_Close();
          this.$bus.$emit('closeDialog');//断开设备关闭所有弹窗
          this.$bus.$emit('backToHome',true);
        }
        else {
          this.showTips(this.language.DialogSaveMacroFirst,true);
        }
      },
      showTips(text,showButton) {
        this.tipsText = text;
        this.showButton = showButton;
        this.tipsVisible = true;
        this.$refs.tipsDialog.visible = this.tipsVisible;      
      },
      hideTips() {
        this.tipsVisible = false;
        this.$refs.tipsDialog.visible = this.tipsVisible;   
      },
      handleWindowClose(event) {
        // 在这里处理窗口关闭逻辑
        // 例如：提示用户是否真的要离开页面
        HIDHandle.Device_Close();
      }
    },
    watch: {
      "radio": {
        handler() {
          if(this.saveMacroFirst) {
            this.radio = this.lastRadio;
            this.showTips(this.language.DialogSaveMacroFirst,true);
          }
          else {
            this.lastRadio = this.radio;
          }
        }
      },
      "info.connectState": {
        handler() {
          if(this.info.connectState == HIDHandle.DeviceConectState.Connected) {       
            this.$bus.$emit("updateDeviceInfo",this.info);
            this.$bus.$emit("updateMouseUI",this.info.mouseCfg);

            this.hideTips();
            this.batteryShow = true;
          }
          else if(this.info.connectState == HIDHandle.DeviceConectState.Connecting){
            this.showTips(this.language.DialogUpdating,false);
          }
        },
        deep: true
      },
      "info.online": {
        handler() {
          if(this.info.online == false) {
            if(this.info.showOfflineDialog) {
              this.showTips(this.language.DialogOffline,true);
              this.info.showOfflineDialog = false;
            }
          }
        }
      },
      //报告率发生变化
      "info.mouseCfg.reportRate": {
        handler() {
          this.$bus.$emit("updateReportRate",this.info.mouseCfg.reportRate);
        },
        deep: true
      },
      //当前DPI发生变化
      "info.mouseCfg.currentDpi": {
        handler() {
          this.$bus.$emit("updateCurrentDPI",this.info.mouseCfg.currentDpi);
        },
        deep: true
      },
      //更新电量
      "info.battery": {
        handler() {
          this.$bus.$emit("updateBattery",this.info.battery);
        },
        deep: true
      },
      //切换配置
      "info.profile": {
        handler() {
          this.$bus.$emit("updateProfile",this.info.profile);
        },
        deep: true
      },
      //设备版本
      "info.version": {
        handler() {
          this.$bus.$emit("updateVersion",this.info.version);

          if(this.upgrade != null) {

            var download = false;

            if(this.upgrade.device != this.info.version.device) {
              download = true;
            }

            if(this.info.isWired == false) {
              if(this.info.maxReportRate == 8000) {
                if(this.upgrade.dongle8 != this.info.version.dongle) {
                  download = true;
                }
              }
              else if(this.info.maxReportRate == 4000) {
                if(this.upgrade.dongle4 != this.info.version.dongle) {
                  download = true;
                }
              }
              else if(this.info.maxReportRate == 2000) {
                if(this.upgrade.dongle2 != this.info.version.dongle) {
                  download = true;
                }
              }
              else {
                if(this.upgrade.dongle != this.info.version.dongle) {
                  download = true;
                }
              }
            }

            if(download) {
                this.$notify.info({
                  dangerouslyUseHTMLString: true,
                  message: `<a href="${this.upgrade.link}" style="text-decoration: none" target="_blank">${this.language.UpgradeTips}</a>`,
                  offset:100,
                  duration: 0
                });
            }
          }
        },
        deep: true
      }, 
      "info.deviceOpen": {
        handler() {
          if(this.info.deviceOpen == false) {
            this.$bus.$emit('closeDialog');//断开设备关闭所有弹窗
            this.$bus.$emit('backToHome',true);
          }
        },
        deep: true
      }
    },
    created() {   
      this.batteryShow = false;

      this.$bus.$on("themeButtonShow",value => {
        this.themeShow = value;

        this.theme = "light";

        if(value) {
          //查找应用（App）是否保存了上次选择的主题
          var item = localStorage.getItem('theme');
          if(item) {
            var tmp = JSON.parse(item);
            if(tmp == "light" || tmp == "dark") {
              
              this.theme = tmp;
            }
          }
          else {
            //判断系统是不是深色模式
            const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            if(isDarkMode) {
              this.theme = "dark";
            }
          }
          console.log("themeButtonShow 1",item,this.theme);
        }
        console.log("themeButtonShow 2",value,this.theme);
        this.bgImage = window.location.href + `img/bg_${this.theme}.png`;
        if(this.theme == "light")
          this.logo = window.location.href + "img/logo_dark.png";
        else
          this.logo = window.location.href + "img/logo_light.png";
        this.$bus.$emit("themeChange",this.theme);
      });

      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.radio = '1';
        this.lightShow = typeof deviceCfg.lightEffect != "undefined";

        if(typeof deviceCfg.upgrade != "undefined") {
          this.upgrade = deviceCfg.upgrade;
          console.log("upgrade",this.upgrade);
        }
      }); 

      this.$bus.$on("languageChange",lang => {
        if(typeof lang != 'undefined') {
          //获取默认报告率列表
          this.language = lang;
        }
      });

      this.$bus.$on("macroRecording",value => {
        this.macroRecording = value;
      });

      this.$bus.$on("saveMacroFirst",value => {
        this.saveMacroFirst = value;
      });

      
    },
    mounted() {
      window.addEventListener('beforeunload', this.handleWindowClose);
    },
    computed: {
      backgroundStyle() {
        return {
          backgroundImage: `url(${this.bgImage})`,
        };
      },
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.handleWindowClose);
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("languageChange");
      this.$bus.$off("macroRecording");
      this.$bus.$off("saveMacroFirst");
    }
  }
</script>

<style lang="less" scoped>

</style>