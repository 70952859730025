<template>
  <div class="mouse_sensor">
    <dpi-setting></dpi-setting>
    <report-rate></report-rate>
    <sensor-setting v-show="sensorShow"></sensor-setting>
    <dpi-effect v-show="effectShow"></dpi-effect>
  </div>
</template>

<script>
  import DpiSetting from '@/components/Sensor/DpiSetting.vue';
  import ReportRate from '@/components/Sensor/ReportRate.vue';
  import SensorSetting from '@/components/Sensor/SensorSetting.vue';
  import DpiEffect from '@/components/Sensor/DpiEffect.vue';

  export default {
    components: {
      DpiSetting,
      ReportRate,
      SensorSetting,
      DpiEffect
    },
    data() {
      return {
        sensorShow:true,
        effectShow:true,
        sensor:{}
      }
    },
    created() {
      this.$bus.$on("setGlobalSensor",value => {
        this.sensor = value;
      });  

      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.sensorShow = this.sensor.Setting.includes(deviceCfg.sensor);
        this.effectShow = typeof deviceCfg.dpiEffect != "undefined";
      }) 
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("setGlobalSensor");
    }
  }
</script>

<style lang="less" scoped>

</style>