<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    custom-class="dialog_class"
    @open="onOpen"
    @close="onClose"
    :show-close="closeVisible">
    <span slot="footer" class="dialog-footer">
      <div class="flex_column_evenly">
        <span class="dialog_span_padding" lang="DialogPairTips">请让鼠标进入配对状态（同时按住左中右键3秒，直至对码指示灯快闪），并靠近接收器 ，点击“开始配对”或按键盘的空格键</span>
        <div class="flex_row_center content_evenly" style="margin-top: 5vh;">
          <el-button 
          @click="handlePairingClick" 
          @mouseover.stop="handleMouseEnter"
          :class="result == 2 ? 'pair_fail_button pair_button' : 
          (result == 0x03 ? 'pair_success_button pair_button':'dialog_button pair_button')">{{text}}</el-button>
          <el-button 
          @click="handleCancelClick" 
          v-show="cancelVisible"
          class="dialog_button pair_button" 
          lang="Cancel">取 消</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
</template>
  
<script>
  import HIDHandle from '@/assets/js/HIDHandle'

  export default {
    extends: 'el-dialog',
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        visible:false,
        closeVisible:true,
        cancelVisible:true,
        text:"开始配对",
        pairResult:HIDHandle.pairResult,
        result:0,
        pairing:'',
        pairSuccess:'',
        pairFail:'',
        startPair:'', 
        visitMode:false, 
        info:HIDHandle.deviceInfo,  
      }
    },
    methods: {
      onOpen() {
        // 打开对话框时的逻辑
        this.text = this.startPair;
        this.result = 0;
        this.cancelVisible = true;
        this.$bus.$emit("pairDialogOpen",true);
        window.addEventListener('keydown', this.handleKeyDown);
      },
      onClose() {
        // 关闭对话框时的逻辑
        this.$bus.$emit("pairDialogOpen",false);
        window.removeEventListener('keydown', this.handleKeyDown);
      },
      handleKeyDown(event) {
        // 检查按键并执行操作
        if (event.code === 'Space') {
          // 执行某些操作
          this.handlePairingClick();
        }
      },
      handlePairingClick() {
        if(this.visitMode == false) {
          console.log("result",this.result);
          if(this.result == HIDHandle.DevicePairResult.Fail ||
            this.result == 0) {
            this.text = this.pairing;
            this.cancelVisible = false;
            this.closeVisible = false;
            HIDHandle.Set_Device_EnterPairMode();
          }
          else if(this.result == HIDHandle.DevicePairResult.Success) {
            this.visible = false;
          }
        }
      },
      handleMouseEnter() {
        console.log("handleMouseEnter");
        if(this.result == HIDHandle.DevicePairResult.Fail) {
          this.text = this.startPair;
          this.result = 0;
        }
      },
      handleCancelClick() {
        this.visible = false;
      }
    },
    watch: {
      'pairResult.pairStatus': {
        handler() {
          this.result = this.pairResult.pairStatus;
          console.log("handler result",this.result);
          if(this.result == HIDHandle.DevicePairResult.Pairing) {

          }
          else {
            this.closeVisible = true;
            
            if(this.result == HIDHandle.DevicePairResult.Success) {
              this.text = this.pairSuccess;
            }
            else if(this.result == HIDHandle.DevicePairResult.Fail) {
              this.text = this.pairFail;
            }
          }
        }
      },
      "info.deviceOpen": {
        handler() {
          //配对的时候拔出USB要关闭配对窗口
          if(this.info.deviceOpen == false) {
            if(this.visible) {
              this.visible = false;
            }       
          }
        },
        deep: true
      }
    },
    created() {
      this.$bus.$on("setDriverCfg",driverCfg => {
        if(driverCfg.visit == true) {
          this.visitMode = true;
        }
      });

      this.$bus.$on("languageChange",lang => {
        if(typeof lang != 'undefined') {
          this.startPair = lang.StartPair;
          this.pairing = lang.Pairing,
          this.pairFail = lang.PairFail;
          this.pairSuccess = lang.PairSuccess;
        }
      });

      this.$bus.$on('closeDialog',() => {
        if(this.visible) {
          this.visible = false;
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off('setDriverCfg');
      this.$bus.$off('languageChange');
      this.$bus.$off('closeDialog');
    },
  }
</script>

<style lang="less" scoped>
  .pair_button {
    width:calc(var(--font-size) * 8);
  }
</style>