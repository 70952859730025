<template>
  <div class="flex_column_evenly sensor_setting">
    <!-- title -->
    <div class="flex_row" style="align-items: center;">
      <i class="incfont el-icon-title"></i>
      <span lang="SensorSetting" class="margin_left">Sensor设置</span>
    </div>

    <!-- 模式选择 -->
    <div class="flex_row" style="justify-content: space-between; align-items: center;">
      <div 
      v-show="sensorModeShow"
      class="flex_column_start">
        <el-tooltip :content="sensorModeTips" placement="top" :effect="effect">
          <span lang="SensorMode">模式选择</span> 
        </el-tooltip>
        <select
          v-model="sensorMode"
          @change="handleSensorModeChange"
          :disabled="corderMode">
          <option 
          v-for="item in sensorModeOptions" 
          :key="item.value" 
          :value="item.value"
          :disabled="item.value == 256">{{item.option}}</option>
        </select>        
      </div>

      <!-- LOD选择 -->
      <div 
      v-show="lodShow"
      class="flex_column_start">
        <el-tooltip :content="lodTips" placement="top" :effect="effect">
          <span lang="LOD">LOD</span> 
        </el-tooltip>
        <select
          v-model="lod"
          @change="handleLODChange">
          <option 
          v-for="item in lodOptions" 
          :key="item.value" 
          :value="item.value">{{item.option}}</option>
        </select>        
      </div>

      <!-- 火力全开 -->
      <div 
      v-show="performanceShow"
      class="flex_column_start">
        <div class="flex_row" style="justify-content: center;">
          <el-checkbox 
          v-model="performanceState"
          @change="handlePerformanceStateChange">
          </el-checkbox>
          <el-tooltip :content="performanceTips" placement="top" :effect="effect">
            <span lang="Performance" class="margin_left">火力全开</span>
          </el-tooltip>
        </div>
        <select
          v-model="performance"
          @change="handlePerformanceChange"
          :disabled="performanceState == false">
          <option 
          v-for="item in performanceOptions" 
          :key="item.value" 
          :value="item.value">{{item.option}}</option>
        </select>        
      </div>

      <!-- sensor设置 -->
      <div class="flex_column">
        <div 
        v-show="rippleShow"
        class="sensor_switch">
          <el-switch 
          v-model="ripple"
          @click.native="handleRippleClick"></el-switch>
          <el-tooltip :content="rippleTips" placement="top" :effect="effect">
            <span lang="Ripple" class="margin_left">波纹控制</span> 
          </el-tooltip>
        </div>
        
        <div 
        v-show="angleShow"
        class="sensor_switch">
          <el-switch 
          v-model="angle"
          @click.native="handleAngleClick"></el-switch>  
          <el-tooltip :content="angleTips" placement="top" :effect="effect">
            <span lang="Angle" class="margin_left">直线修正</span>
          </el-tooltip>
        </div>  

        <div 
        v-show="motionSyncShow"
        class="sensor_switch">
          <el-switch 
          v-model="motionSync"
          @click.native="handleMotionSyncClick"></el-switch> 
          <el-tooltip :content="motionSyncTips" placement="top" :effect="effect">
            <span lang="MotionSync" class="margin_left">Motion sync</span>
          </el-tooltip>
        </div>
      </div>        
    </div>     
  </div> 
</template>

<script>
import HIDHandle from '@/assets/js/HIDHandle';
  export default {
    data() {
      return {
        sensor:{},
        sensorModeShow:true,
        sensorMode:0,
        lastSensorMode:0,
        sensorModeOptions:[],
        corderOptionDisable:true,
        corderMode:false,

        lodShow:true,
        lod:1,
        lastLOD:1,
        lodOptions:[],

        performanceShow:true,
        performanceState:false,
        performance:6,
        lastPerformance:6,
        performanceOptions:[],

        rippleShow:true,
        ripple:false,

        angleShow:true,
        angle:false,

        motionSyncShow:true,
        motionSync:false,

        effect:'light',
        sensorModeTips:'',
        lodTips:'',
        performanceTips:'',
        rippleTips:'',
        angleTips:'',
        motionSyncTips:'',
      }
    },
    methods: {
      async handleSensorModeChange() {
        if(await HIDHandle.Set_MS_SensorMode(this.sensorMode)) {
          this.lastSensorMode = this.sensorMode;
        }
        else {
          this.sensorMode = this.lastSensorMode;
        }
      },
      setCorderMode(result) {
        if(result) {
          this.sensorMode = 256;
          this.corderMode = true;
        }
        else {
          this.sensorMode = this.lastSensorMode;
          this.corderMode = false;
        }
      },
      async handleLODChange() {
        if(await HIDHandle.Set_MS_LOD(this.lod)) {
          this.lastLOD = this.lod;
        }
        else {
          this.lod = this.lastLOD;
        }
      },
      async handlePerformanceStateChange() {
        var performance = this.performanceState;
        if(await HIDHandle.Set_MS_PerformanceState(performance ? 1 : 0) == false) {
          
          this.performanceState = !performance;
        }
      },
      async handlePerformanceChange() {
        if(await HIDHandle.Set_MS_PerformanceTime(this.performance)) {
          this.lastPerformance = this.performance;
        }
        else {
          this.performance = this.lastPerformance;
        }
      },
      async handleRippleClick() {
        var ripple = this.ripple;
        if(await HIDHandle.Set_MS_Ripple(ripple? 1 : 0) == false) {
          this.ripple = !this.ripple;
        }
      },
      async handleAngleClick() {
        var angle = this.angle;
        if(await HIDHandle.Set_MS_Angle(angle? 1 : 0) == false) {
          this.angle = !this.angle;
        }
      },
      async handleMotionSyncClick() {
        var motionSync = this.motionSync;
        if(await HIDHandle.Set_MS_MotionSync(motionSync? 1 : 0) == false) {
          this.motionSync = !this.motionSync;
        }
      },
      updateSensorSetting(cfg) {
        if(this.sensorModeShow)
          this.lastSensorMode = this.sensorMode = cfg.sensorMode;

        if(this.lodShow)  
          this.lastLOD = this.lod = cfg.lod;

        if(this.performanceShow) {
          this.performanceState = cfg.performanceState;
          this.lastPerformance = this.performance = cfg.performance;  
        }
 
        if(this.rippleShow)
          this.ripple = cfg.ripple;

        if(this.angleShow)
          this.angle = cfg.angle;

        if(this.motionSyncShow)  
          this.motionSync = cfg.motionSync;       
      }
    },
    created() {
      this.$bus.$on("setGlobalSensor",value => {
        this.sensor = value;
      });      

      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.sensorModeShow = typeof deviceCfg.sensorMode != "undefined";
        this.lodShow = typeof deviceCfg.lod != "undefined";
        this.performanceShow = typeof deviceCfg.performance != "undefined";
        this.rippleShow = (this.sensor.Ripple.includes(deviceCfg.sensor)) && (typeof deviceCfg.ripple != "undefined");
        this.angleShow = (this.sensor.Angle.includes(deviceCfg.sensor)) && (typeof deviceCfg.angle != "undefined");
        this.motionSyncShow = (this.sensor.MotionSync.includes(deviceCfg.sensor)) && (typeof deviceCfg.motionSync != "undefined");

        this.updateSensorSetting(deviceCfg); 

        if(HIDHandle.deviceInfo.mouseCfg.sensor.type != "3950") {
          for(var i = 0;i < this.lodOptions.length;i++) {
            if(this.lodOptions[i].value == 3) {
              this.lodOptions.splice(i,1);
            }
          }
        }
      });

      this.$bus.$on("updateMouseUI",value => {
        this.updateSensorSetting(value.sensor);
        this.setCorderMode((value.reportRate > 1000) || (HIDHandle.deviceInfo.isWired));
      });

      this.$bus.$on("languageChange",lang => {     
        if(typeof lang != 'undefined') {
          //Sensor模式下拉框
          this.sensorModeOptions = lang.SensorModeOptions;
          //LOD模式下拉框
          this.lodOptions = lang.LODOptions;

          if(HIDHandle.deviceInfo.mouseCfg.sensor.type != "3950") {
            for(var i = 0;i < this.lodOptions.length;i++) {
              if(this.lodOptions[i].value == 3) {
                this.lodOptions.splice(i,1);
              }
            }
          }
          //火力全开下拉框
          this.performanceOptions = lang.PerformanceOptions;

          this.sensorModeTips = lang.SensorModeTips;
          this.lodTips = lang.LODTips;
          this.performanceTips = lang.PerformanceTips;
          this.rippleTips = lang.RippleTips;
          this.angleTips = lang.AngleTips;
          this.motionSyncTips = lang.MotionSyncTips;
        }
      });

      //获取主题色（light/dark）
      this.$bus.$on('themeChange',theme => {
        this.effect = theme;
      });

      //判断是不是corder模式
      this.$bus.$on('setSensorCorder',result => {
        this.setCorderMode(result);
      });
    },
    beforeDestroy() {
      this.$bus.$off("setGlobalSensor");
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("updateMouseUI");
      this.$bus.$off("languageChange");
      this.$bus.$off("themeChange");
    }
  }
</script>

<style lang="less" scoped>

</style>