import { render, staticRenderFns } from "./MouseSensor.vue?vue&type=template&id=19d9e357&scoped=true"
import script from "./MouseSensor.vue?vue&type=script&lang=js"
export * from "./MouseSensor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19d9e357",
  null
  
)

export default component.exports