<template>
  <el-dialog
  :title="title"
  :visible="visible"
  custom-class="dialog_class"
  @open="onOpen"
  @close="onClose">
    <span slot="footer" class="dialog-footer">
      <div class="flex_column_evenly">
        <span class="dialog_span_padding">{{text}}</span>
        <div class="flex_row_center content_evenly" style="margin-top: 5vh;"  v-show="showButton">
          <el-button 
          @click="handleOKClick" 
          class="dialog_button" lang="OK">确 定</el-button>
          <el-button 
          @click="handleCancelClick" 
          class="dialog_button" lang="Cancel">取 消</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    extends: 'el-dialog',
    props: {
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default:''
      },
      showButton: {
        type: Boolean,
        default:true
      }
    },
    data() {
      return {
        visible:false,
        sendResult:false,
      }
    },
    methods: {
      onOpen() {
        // 打开对话框时的逻辑
        this.sendResult = false;
      },
      onClose() {
        // 关闭对话框时的逻辑
        this.$bus.$emit("tipsResult",false); 
        this.visible = false;
      },
      handleOKClick() {
        this.sendResult = true;
        this.$bus.$emit("tipsResult",true);
        this.visible = false;
      },
      handleCancelClick() {
        this.sendResult = true;
        this.$bus.$emit("tipsResult",false);
        this.visible = false;
      }
    },
    created() {
      this.$bus.$on('closeDialog',() => {
        if(this.visible) {
          this.visible = false;
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off('closeDialog');
    }
  }
</script>

<style lang="less" scoped>

</style>