<template>
  <select 
  v-model="profile"
  :disabled="disableChange"
  @change="handleProfileChange"
  class="import_button_width">
    <option 
      v-for="item in profileOptions" 
      :key="item.value" 
      :value="item.value">
      {{ item.option }}
    </option>
  </select>  
</template>

<script>
  import HIDHandle from '@/assets/js/HIDHandle'
  export default {
    data() {
      return {
        profile:0,
        lastProfile:0,
        profileOptions:[],
        disableChange:false,
      }
    },
    methods: {
      //切换配置
      async handleProfileChange() {
        if(await HIDHandle.Set_Device_Profile(this.profile)) {
          this.lastProfile = this.profile;
        }
        else {
          this.profile = this.lastProfile;
        }
      },
    },
    created() {
      this.$bus.$on("languageChange",lang => {     
        if(typeof lang != 'undefined') {
          //获取默认配置列表
          this.profileOptions = lang.ProfileOptions;
        }
      });

      this.$bus.$on("updateDeviceInfo",value => {
        this.disableChange = value.supportChangeProfile == false;
      });

      this.$bus.$on("updateProfile",value => {
        this.lastProfile = this.profile = value;
      });
    },
    beforeDestroy() {
      this.$bus.$off("updateProfile");
      this.$bus.$off("languageChange");
    }
  }
</script>

<style lang="less" scoped>

</style>