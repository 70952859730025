<template>
  <div>
    <div class="flex_column" style="align-items: baseline;">
      <el-button 
      @click="handleExportClick"
      class="import_button_width margin_top"><span lang="Export">导出配置</span></el-button>
      <input 
      type="file" 
      ref="fileInput"
      @change="openFile"
      accept=".bin"
      v-show="false"/>
      <el-button 
      @click="handleImportClick"
      class="import_button_width margin_top"><span lang="Import">导入配置</span></el-button>
      <el-button 
      @click="handleRestoreClick"
      class="import_button_width margin_top"><span lang="Restore">恢复默认</span></el-button>
    </div>

    <tips 
    ref="tipsDialog" 
    :text="text"
    :showButton="tipsButton"></tips>

    <input-name
    ref="inputNameDialog"
    :text="inputName"></input-name>
  </div>
</template>

<script>
  import Tips from '../Dialog/Tips.vue';
  import InputName from '../Dialog/InputName.vue'

  import HIDHandle from '@/assets/js/HIDHandle';
  import UserConvert from '@/assets/js/UserConvert';

  export default {
    components: {
      Tips,
      InputName,
    },
    data() {
      return {
        isRestoring:HIDHandle.deviceInfo.isRestoring,
        language:{},
        text:'',
        tipsButton:true,
        inputName:'',
        maxDpiCount:5,
      }
    },
    methods: {
      handleExportClick() {
        this.inputName = this.language.DialogExportName;
        this.$refs.inputNameDialog.visible = true;    
      },
      //打开文件
      openFile(event) {
        const file = event.target.files[0];

        if (file) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            try {
              // 假设二进制文件是按数组格式存储的，这里我们直接转成Uint8Array
              const buffer = e.target.result;
              var arrayBuffer = new Uint8Array(buffer);

              var len = arrayBuffer.length;
              var stringArray = new Uint8Array(0x20);
              for(var i = 0;i < 0x20;i++) {
                stringArray[i] = arrayBuffer[len - 0x40 + i];
              }

              var company = UserConvert.UTF8_To_String(stringArray).replace(/\0+$/, '');

              if(company == 'Compx Inc') {
                var mismatch = false;
                for(var i = 0;i < 0x10;i++) {
                  stringArray[i] = arrayBuffer[len - 0x20 + i];
                }
                var type = UserConvert.UTF8_To_String(stringArray).replace(/\0+$/, '');

                for(var i = 0;i < 0x10;i++) {
                  stringArray[i] = arrayBuffer[len - 0x10 + i];
                }
                var sensor = UserConvert.UTF8_To_String(stringArray).replace(/\0+$/, '');


                if(type != HIDHandle.deviceInfo.type) {
                  mismatch = true;
                }
                else {
                  if(type == "mouse") {
                    if(sensor != HIDHandle.deviceInfo.mouseCfg.sensor.type) {
                      mismatch = true;
                    }
                  }
                }

                if(mismatch) {
                  this.text = this.language.DialogEConfigMismatch;
                  this.tipsButton = true;
                  this.$refs.tipsDialog.visible = true;   
                }
                else {
                  var flashData = [];

                  for(var i = 0;i < arrayBuffer.length - 0x40;i++) {
                    flashData[i] = arrayBuffer[i];
                  }

                  //如果报告率大于最大报告率，只能显示当前最大报告率
                  var reportRate = UserConvert.FlashData_To_ReportRate(flashData[0]);
                  if(reportRate > HIDHandle.deviceInfo.maxReportRate) {
                    reportRate = HIDHandle.deviceInfo.maxReportRate;
                    flashData[0] = UserConvert.ReportRate_To_FlashData(reportRate);
                    flashData[1] = 0x55 - flashData[0];
                  }

                  //如果DPI总档位大于默认最大档位，设置默认最大的档位
                  if(flashData[2] > this.maxDpiCount) {
                    flashData[2]  = this.maxDpiCount;
                    flashData[3] = 0x55 - flashData[2];
                  }

                  //如果当前DPI档位大于默认最大档位，设置为最大档位
                  if(flashData[4] > (this.maxDpiCount - 1)) {
                    flashData[4]  = this.maxDpiCount - 1;
                    flashData[5] = 0x55 - flashData[2];
                  }                 

                  console.log("import",company,sensor,flashData);
                  await HIDHandle.Write_Mouse_Flash(flashData);
                }
              }
              else {
                this.text = this.language.DialogConfigError;
                this.tipsButton = true;
                this.$refs.tipsDialog.visible = true;   
              }
            } catch (error) {
              console.log("import file error",error);
            }
          };
          reader.readAsArrayBuffer(file);
          //选择相同的文件时不会重新触发input file的change，所以这边需要设置为空
          this.$refs.fileInput.value = '';
        }     
      },
      handleImportClick() {
        // 触发文件选择
        this.$refs.fileInput.click();
      },
      //恢复初始化配置
      handleRestoreClick() {
        if(this.isRestoring == false) {
          this.text = this.language.DialogRestore;
          this.tipsButton = true;
          this.$refs.tipsDialog.visible = true;   
        }
      },
    },
    created() {
      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.maxDpiCount = deviceCfg.dpis.length;
      });

      this.$bus.$on("languageChange",lang => {     
        if(typeof lang != 'undefined') {
          this.language = lang;
        }
      });

      this.$bus.$on("tipsResult",async value => {     
        if(this.$refs.tipsDialog.visible) {
          if(value == true) {
            if(this.text == this.language.DialogRestore) {
              await HIDHandle.Device_Restore();
            }
          }
        }
      });  
      
      this.$bus.$on("inputName",async value => { 
        if(this.$refs.inputNameDialog.visible) {
          if(value.result == true) {  
            // 创建一个Blob对象，里面包含JSON字符串
            var array = new Uint8Array(HIDHandle.flashData.length + 0x40);

            for(var i = 0;i < HIDHandle.flashData.length;i++) {
              array[i] = HIDHandle.flashData[i];
            }

            console.log("export:",HIDHandle.flashData,array);
            //添加公司名作为标识符
            var company = 'Compx Inc';
            var stringArray = UserConvert.String_To_UTF8(company);
            for(var i = 0;i < stringArray.length;i++) {
              array[HIDHandle.flashData.length + i] = stringArray[i];
            }
            
            //添加设备类型和sensor
            stringArray = UserConvert.String_To_UTF8(HIDHandle.deviceInfo.type);        
            for(var i = 0;i < stringArray.length;i++) {
              array[HIDHandle.flashData.length + i + 0x20] = stringArray[i];
            }

            if(HIDHandle.deviceInfo.type == "mouse") {
              stringArray = UserConvert.String_To_UTF8(HIDHandle.deviceInfo.mouseCfg.sensor.type);    

              for(var i = 0;i < stringArray.length;i++) {
                array[HIDHandle.flashData.length + i + 0x30] = stringArray[i];
              }
            }

            console.log("flashData",array);
            const blob = new Blob([array]);

            // 创建一个指向该Blob的URL
            const url = URL.createObjectURL(blob);

            // 创建一个a标签
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', value.name + '.bin');

            // 触发点击事件
            document.body.appendChild(link);
            link.click();

            // 清理并释放资源
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        }    
      });      
    },
    watch: {
      "isRestoring": {
        handler() {
          if(this.isRestoring) {
            this.text = this.language.DialogRestoring;
            this.tipsButton = false;
            this.$refs.tipsDialog.visible = true;
          }
          else {
            this.$refs.tipsDialog.visible = false;
          }
        }
      }
    },
    beforeDestroy() {
      this.$bus.$off("languageChange");
      this.$bus.$off("tipsResult");
      this.$bus.$off("inputName");
    }
  }
</script>

<style lang="less" scoped>

</style>