<template>
  <div>
    <div class="flex_row_center content_between battery_block">
      <span v-show="showBatteryValue">{{battery}}%</span>
      <div style="position: relative;">
        <img 
        :src="batterySrc"
        class="battery_img">
        <div 
        class="battery"
        :class="battery <= 15 ? 'low_battery_color' :(battery == 100 ? 'full_battery_color' : 'normal_battery_color' )"
        :style="{width:batteryWidth}">
        </div>
        <img 
        v-show="charging" 
        :src="chargeSrc" class="charging charging_img">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        battery:100,
        charging:false,
        defaultFull:67, 
        fullWidth:67,  
        showBatteryValue:true,
        batterySrc:"/img/battery.png",
        chargeSrc:"/img/charging.png",
      }
    },
    computed: {
      batteryWidth() {
        return (this.battery * this.fullWidth / 100)+ 'px';
      }
    },
    mounted() {
      this.batterySrc = window.location.href + "img/battery.png";
      this.chargeSrc = window.location.href + "img/charging.png";

      this.$bus.$on("setDriverCfg",driverCfg => {
        this.showBatteryValue = driverCfg.showBatteryValue;
      });

      this.$bus.$on("updateBattery",value => {
        this.battery = value.level;
        this.charging = value.charging;
      });

      this.$bus.$on("widthResize",value => {
        this.fullWidth = this.defaultFull * value;
      });  
    },
    beforeDestroy() {
      this.$bus.$off("updateBattery");
      this.$bus.$off("widthResize");
    }
  }
</script>

<style lang="less" scoped>

</style>