<template>
  <div class="flex_column language">
    <div class="flex_row_center">
      <i class="incfont el-icon-title"></i>
      <span lang="Language" class="margin_left">语言</span>
    </div>
    <select 
    v-model="language" 
    @change="handleLanguageChange"
    class="margin_top">
      <option 
        v-for="item in languageOptions" 
        :key="item.value" 
        :value="item.value">
        {{ item.option }}
      </option>
    </select>    
  </div> 
</template>

<script>
  export default {
    data() {
      return {
        languageOptions:[],
        languages:[],
        language:"zh-CN",
      }
    },
    methods: {
      handleLanguageChange() {   
        var i = 0; 
        for(i = 0;i < this.languageOptions.length;i++) {
          if(this.language == this.languageOptions[i].value) {
            break;
          }
        }
  
        this.$bus.$emit("languageOptionsChange",this.language);
        this.$bus.$emit("languageChange",this.languages[i]);
      },
    },
    created() {
      this.$bus.$on("setGlobalLanguages",(options,languages) => {
        this.languageOptions = options; 
        this.languages = languages;
      });

      this.$bus.$on("languageOptionsChange",value => {
        this.language = value;
      });
    },
    beforeDestroy() {
      this.$bus.$off('setGlobalLanguages');
      this.$bus.$off('languageOptionsChange');
    }
  }
</script>

<style lang="less" scoped>

</style>