<template>
    <div class="flex_column_evenly dpi_effect">
      <!-- title -->
      <div class="flex_row " style="align-items: center;">
        <i class="incfont el-icon-title"></i>
        <span lang="DPIEffect" class="margin_left">DPI灯效</span>
      </div>

      <!-- DPI灯效模式 -->
      <div class="flex_row" style="align-items: center; justify-content: space-between; ">
        <select
          v-model="mode"
          @change="handleDPIEffectChange">
          <option 
          v-for="item in effectOptions" 
          :key="item.value" 
          :value="item.value">{{item.option}}</option>
        </select>  

        <!-- 亮度和速度设置 -->
        <div class="flex_column" >
          <div class="flex_row_end_center">
            <span lang="Brightness" >亮度</span>
            <el-slider
            v-model="brightness"
            @change="handleBrightnessChange"
            :show-tooltip="false"
            :min="min"
            :max="max"
            :disabled="brightnessDisable"
            class="margin_left dpiEffect_slider_width"></el-slider>
            <span class="margin_left">{{brightness}}</span>
          </div>
          <div class="flex_row_end_center">
            <span lang="Speed">速度</span>
            <el-slider
            v-model="speed"
            @change="handleSpeedChange"
            :show-tooltip="false"
            :min="min"
            :max="5"
            :disabled="speedDisable"
            class="margin_left dpiEffect_slider_width"></el-slider>
            <span class="margin_left">{{speed}}</span>
          </div>          
        </div>      
      </div>   
    </div>  
 </template>
 
 <script>
  import HIDHandle from '@/assets/js/HIDHandle';

  export default {
     data() {
      return {
        mode:0,
        lastMode:0,
        effectOptions:[],
        min:1,
        max:10,
        brightnessDisable:false,
        brightness:5,
        lastBrightness:5,
        speedDisable:false,
        speed:3,
        lastSpeed:3
      }
     },
     methods: {
      effectToDisable() {
        switch(this.mode) {
          case 0:
            this.brightnessDisable = true;
            this.speedDisable = true;
            break;

          case 1:
            this.brightnessDisable = false;
            this.speedDisable = true;
            break;

          case 2:
            this.brightnessDisable = true;
            this.speedDisable = false;
            break;
        }
      },
      async handleDPIEffectChange() {
        console.log("Dpi mode",this.mode);
        var success = false;
        if(this.mode == 0) {
          success = await HIDHandle.Set_MS_DPILightOff();
        }
        else {
          success = await HIDHandle.Set_MS_DPILightMode(this.mode);
        }

        if(success) {
          this.lastMode = this.mode;
          this.effectToDisable();
        }
        else {
          this.mode = this.lastMode;
        }
      },
      async handleBrightnessChange() {
        if(await HIDHandle.Set_MS_DPILightBrightness(this.brightness)) {
          this.lastBrightness = this.brightness;
        }
        else {
          this.brightness = this.lastBrightness;
        }  
      },
      async handleSpeedChange() {
        if(await HIDHandle.Set_MS_DPILightSpeed(this.speed)) {
          this.lastSpeed = this.speed;
        }
        else {
          this.speed = this.lastSpeed;
        }     
      }
     },
    created() {
      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        if(typeof deviceCfg.dpiEffect != "undefined") {
          this.lastMode = this.mode = deviceCfg.dpiEffect.mode;
          this.effectToDisable();
          this.lastBrightness = this.brightness = deviceCfg.dpiEffect.brightness;
          this.lastSpeed = this.speed = deviceCfg.dpiEffect.speed;
        }
      }); 

      this.$bus.$on("updateMouseUI",value => {
        this.lastMode = this.mode = value.dpiEffect.state == false? 0 : value.dpiEffect.mode;
        this.effectToDisable();
        this.lastBrightness = this.brightness = value.dpiEffect.brightness;
        this.lastSpeed = this.speed = value.dpiEffect.speed;
      });

      this.$bus.$on("languageChange",lang => {
        if(typeof lang != 'undefined') {
          //获取DPI灯光模式下拉框内容
          this.effectOptions = lang.DPIEffectOptions;
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("updateMouseUI");
      this.$bus.$off("languageChange");
    }
  }
 </script>
 
 <style lang="less" scoped>

 </style>