<template>
  <div class="flex_column_evenly device_info">
    <div class="flex_row_center">
      <i class="incfont el-icon-title"></i>
      <span lang="DeviceInfo" class="margin_left">设备信息</span>
    </div>
    <div class="flex_row device_info">
      <div class="flex_column_evenly">
        <div class="flex_row_center">
          <span lang="DongleVersion">接收器固件版本</span>
          <span>&nbsp;{{dongleVersion}}</span>
        </div>
        <div class="flex_row_center">
          <span lang="MouseVersion">鼠标固件版本</span>
          <span>&nbsp;{{mouseVersion}}</span>
        </div>   
      </div>

      <div v-show="upgradeShow" class="flex_center button_gap">
        <el-tooltip :content="language.UpgradeTips" placement="top" :effect="effect">
          <el-button class="upgrade_button" lang="Upgrade">Upgrade</el-button>
        </el-tooltip>
      </div>
    </div>
  
  </div> 
</template>

<script>
  export default {
    data() {
      return {
        dongleVersion:'--',
        mouseVersion:'v1.07',
        language:{},
        upgrade:null,
        upgradeShow:false,
        effect:'light',
      }
    },
    created() {
      this.$bus.$on("updateDeviceInfo",info => {
        if(info.isWired == false) {
          this.dongleVersion = info.version.dongle;
        }
        this.mouseVersion = info.version.device;

        if(this.upgrade != null) {

          var download = false;

          if(this.upgrade.device != info.version.device) {
            download = true;
          }

          if(info.isWired == false) {
            if(info.maxReportRate == 8000) {
              if(this.upgrade.dongle8 != info.version.dongle) {
                download = true;
              }
            }
            else if(info.maxReportRate == 4000) {
              if(this.upgrade.dongle4 != info.version.dongle) {
                download = true;
              }
            }
            else if(info.maxReportRate == 2000) {
              if(this.upgrade.dongle2 != info.version.dongle) {
                download = true;
              }
            }
            else {
              if(this.upgrade.dongle != info.version.dongle) {
                download = true;
              }
            }
          }

          this.upgradeShow = download;
        }
      });

      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        if(typeof deviceCfg.upgrade != "undefined") {
          this.upgrade = deviceCfg.upgrade;
        }
      }); 

      //获取主题色（light/dark）
      this.$bus.$on('themeChange',theme => {
        this.effect = theme;
      });

      this.$bus.$on("languageChange",lang => {     
        if(typeof lang != 'undefined') {
          this.language = lang;
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off("updateDeviceInfo");
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("themeChange");
      this.$bus.$off("languageChange");
    }
  }
</script>

<style lang="less" scoped>
  
</style>