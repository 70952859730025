<template>
  <div class="mouse_setting">
    <language></language> 
    <device-info></device-info>
    <pair></pair>
    <sleep-time></sleep-time>
    <advanced-setting v-show="longDistanceShow"></advanced-setting>
    <company-info></company-info>
  </div>
</template>

<script>
  import Language from '@/components/Setting/Language.vue';
  import DeviceInfo from '@/components/Setting/DeviceInfo.vue';
  import Pair from '@/components/Setting/Pair.vue' 
  import SleepTime from '@/components/Setting/SleepTime.vue';
  import AdvancedSetting from '@/components/Setting/AdvancedSetting.vue'
  import CompanyInfo from '@/components/Setting/CompanyInfo.vue'

  export default {
    components: {
      Language,
      DeviceInfo,
      Pair,
      SleepTime,
      AdvancedSetting,
      CompanyInfo
    },
    data() {
      return {
        sleepTimeShow:true,
        longDistanceShow:false,
      }
    },
    created() {
      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.sleepTimeShow = !deviceCfg.light;
      });
      
      this.$bus.$on("updateMouseUI",value => {
        this.longDistanceShow = value.supportLongDistance;
      });   
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("updateMouseUI");
    }
  }
</script>

<style lang="less" scoped>

</style>